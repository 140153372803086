@use "../../../scss/_functions.scss";

.preview-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.preview-image {
    width: 50%;
    height: 50%;
}

.app-default-image {
    display: block;
    width: 100%;
    max-width: 250px;
    height: auto;

    &.--small {
        max-width: 100px;
    }
}

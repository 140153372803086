@use "../../../scss/functions" as fn;
$header-height: fn.pxToRem(70);

.footer-main-container {
    height: $header-height;
    padding: 0;
    background-color: var(--block-header);
    position: relative;
    z-index: 2;
    flex: 0 0 $header-height;
    font-size: 0.875rem;

    .footer-container {
        width: 100%;
        background-color: var(--block-header);
        height: $header-height;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        color: var(--text-main-default);

        nav {
            width: 100%;
            height: 100%;
            padding: 0 5px 5px 5px;
            box-sizing: border-box;
        }

        ul {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding-bottom: 5px;

            li {
                flex: 1;
                height: 100%;
            }

            a {
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
                border-top: 3px var(--block-header) solid;

                > span {
                    text-align: center;

                    &.--icon-idle {
                        display: block;
                    }

                    &.--icon-active {
                        display: none;
                    }
                }

                &:hover,
                &.active {
                    border-top: 3px var(--element-evidenceb-default) solid;
                    color: var(--element-evidenceb-default);

                    > span {
                        &.--icon-idle {
                            display: none;
                        }

                        &.--icon-active {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@use "../../scss/functions" as fn;
@use "../../scss/_mixins.scss";
@use "../../scss/Variables.scss" as vars;

.ds-tabs {
    [role="tablist"] {
        display: flex;
    }

    [role="tab"] {
        cursor: pointer;
    }

    &--rectangular {
        [role="tablist"] {
            [role="tab"] {
                width: 100%;
                padding: 16px;
                border-bottom: 1px solid;
                font-weight: 400;
                font-size: fn.pxToRem(14);
                box-sizing: border-box;
                text-align: center;

                &[aria-selected="true"] {
                    border-width: 3px;
                    font-weight: 500;
                }
            }
        }

        &.ds-tabs--default-color {
            [role="tab"] {
                color: var(--element-tab-primary-inactive-text-default);
                border-color: var(
                    --element-tab-primary-inactive-border-default
                );
                box-sizing: border-box;

                &:focus:not(:focus-visible) {
                    outline: 0;
                }

                &:focus-visible {
                    border: 2px solid;
                }

                &[aria-selected="true"] {
                    color: var(--element-tab-primary-active-text-default);
                    border-color: var(--ds-tabs-accent-color);
                }

                &:hover {
                    background-color: var(--ds-tabs-accent-color);
                }
            }
        }

        &.ds-tabs--variant-accent {
            [role="tablist"] {
                [role="tab"] {
                    background-color: var(
                        --element-tab-primary-inactive-default
                    );
                    color: var(--element-tab-primary-inactive-text-default);

                    &:hover,
                    &[aria-selected="true"] {
                        background-color: var(--ds-tabs-accent-color);
                        color: var(--element-tab-primary-active-text-default);
                        border-color: var(--ds-tabs-accent-color);
                    }

                    &:hover:not([aria-selected="true"]) {
                        background-color: var(
                            --element-tab-primary-inactive-hover
                        );
                        color: var(--element-tab-primary-inactive-text-hover);
                    }
                }
            }
        }

        &.ds-tabs--variant-accent-border {
            [role="tablist"] {
                [role="tab"] {
                    &:hover {
                        background-color: var(
                            --element-tab-primary-inactive-default
                        );
                    }

                    &[aria-selected="true"] {
                        border-color: var(--ds-tabs-accent-color);
                    }
                }
            }
        }
    }

    &--round {
        background: none;

        [role="tablist"] {
            background: none;
            align-items: flex-end;

            [role="tab"] {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30%;
                height: 45px;
                padding: 0;
                border: none;
                border-radius: 10px 10px 0 0;
                transition: all 0.2s ease-out;
                background: var(--element-tab-primary-inactive-default);
                color: var(--element-tab-primary-inactive-text-default);
                border-bottom: none;

                &[aria-selected="true"],
                &:hover {
                    height: 55px;
                    font-size: 1.1em;
                    color: var(--element-tab-primary-active-text-default);
                    font-weight: 500;
                }

                &:hover:not([aria-selected="true"]) {
                    background-color: var(--element-tab-primary-inactive-hover);
                    color: var(--element-tab-primary-inactive-text-hover);
                }

                &:first-child {
                    width: 60%;
                }

                &:nth-child(2) {
                    margin: 0 5% 0 auto;
                }

                span {
                    padding: 0 16px;
                    text-align: left;
                }
            }
        }

        [role="tabpanel"] {
            background: var(--block);
            border-radius: 0 10px 10px 10px;
            border-top: none;
            @include mixins.default-box-shadow;
            height: calc(100% - (35px + 16px));
            padding: 16px;
        }
    }
}

@use "../../scss/functions" as fn;

.avatars {
    position: relative;
    height: 40px;
    width: 40px;

    .avatar {
        position: absolute;
        width: 26px;
        height: 26px;
        font-size: fn.pxToRem(11);
        border: 1px solid var(--text-main-inverted);

        &:first-child {
            top: 0;
            left: 0;
        }

        &:last-child {
            right: 0;
            bottom: 0;
        }
    }
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: var(--element-background-avatar);
    color: var(--text-main-inverted);
    text-transform: uppercase;

    font-weight: 600;
    font-size: fn.pxToRem(14);

    height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 3px;
    > img {
        height: 100%;
        width: 100%;
        margin: 1%;
    }

    &.big {
        height: 40px;
        width: 40px;
    }
}

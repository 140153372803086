.ds-removable-banner {
    display: flex;
    padding: 24px 40px;
    justify-content: left;
    align-items: left;
}

.ds-removable-banner--informational {
    background-color: var(--element-evidenceb-disabled);
    color: var(--text-main-default);
}

.ds-removable-banner__content {
    margin: 0 8px 0 8px;
}

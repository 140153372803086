$mobilePadding: 18px 16px;

.page-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 18px 40px 15px 40px;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    @media screen and (max-width: 768px) {
        padding: $mobilePadding;

        &:has(.page-header) {
            padding: 0;

            .page-header {
                padding: $mobilePadding;
            }

            > div {
                padding: 16px;
                box-sizing: border-box;
            }
        }
    }
}

@use "../../scss/functions";

#notifications-widget__button {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    color: var(--text-main-default);

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 1px solid var(--text-main-default);
        border: 1px solid var(--text-main-inverted);
    }

    &:hover {
        background: var(--element-navigation-default);
    }

    .notifications-widget__button__number {
        background: rgba(224, 62, 26, 1);
        color: white;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        right: 6px;
        font-size: functions.pxToRem(12);
    }
}

#notifications-widget__dialog {
    position: fixed;
    right: 215px;
    top: 65px;
    background: var(--block-header);
    width: 470px;
    padding: 0;
    box-sizing: border-box;
    z-index: 9999;

    @media screen and (max-width: 767px) {
        width: 100%;
        right: 0;
    }

    .notifications-widget__content {
        height: 100%;
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 1px solid var(--text-main-default);
        border: 1px solid var(--text-main-inverted);
    }

    footer {
        padding: 32px;

        a {
            display: block;
            text-align: right;
        }
    }
}

@use "../../scss/functions";

.ds-segmented-control {
    padding: 5px;
    display: flex;
    width: fit-content;
    border-radius: 9px;
    color: var(--text-main-default);
    box-sizing: border-box;

    .ds-segmented-control__button {
        width: 170px;
        padding: 8px 12px;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        gap: 10px;
        border: 1px solid transparent;
        border-radius: 6px;
        font-size: functions.pxToRem(14);
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &--selected {
            font-weight: 700;
        }

        &.ds-segmented-control__button--focused.ds-segmented-control__button--selected {
            outline: 1px solid var(--text-main-default);
        }
    }

    &--primary {
        background: var(--element-navigation-default);

        .ds-segmented-control__button--selected {
            color: var(--text-main-inverted);
            background: var(
                --custom-background-color,
                var(--element-evidenceb-default)
            );
        }
    }

    &--secondary {
        background: var(--block);

        .ds-segmented-control__button--selected {
            background: var(--element-navigation-default);
            border: 1px solid var(--text-main-disabled);
        }
    }
}

.empty-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    p {
        text-align: center;
        color: var(--text-main-default);
    }

    img {
        display: block;
        width: 100%;
        max-width: 250px;
        height: auto;
    }
}

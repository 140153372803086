@use "../../scss/_functions.scss";

$border-radius: 8px;
$padding: 24px;
$padding-mobile: 16px;

.ds-card {
    box-sizing: border-box;
    padding: $padding;
    border-radius: $border-radius;
    border: 1px solid
        var(--custom-background-color, var(--block-item1-border-default));
    background: var(--custom-background-color, var(--block-item1-default));
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);

    .ds-card__card-title {
        font-size: functions.pxToRem(20);
        font-weight: 500;
        margin-bottom: 28px;
        color: var(--text-main-default);
    }

    .ds-card__card-content {
        height: 100%;
        width: 100%;
        position: relative;
    }
}

@media screen and (max-width: functions.pxToRem(768)) {
    .ds-card {
        padding: $padding-mobile;
    }
}

.clickable-card {
    border: 1px solid var(--block-item1-border-default);
    position: relative;
    display: flex;
    isolation: isolate;

    &:not(.--disabled):hover {
        border: 1px solid var(--block-item1-border-selected);
        background: var(--custom-background-color, var(--block-item1-selected));
    }

    > a,
    .clickable-card__button {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > .ds-clickable-card__content {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        border-radius: $border-radius;
    }
}

.motivational-message__single-choice-question {
    border-width: 0px;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 2em;
}

.motivational-message__single-choice-question input {
    margin-right: 1.5em;
}

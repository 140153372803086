@use "../scss/functions";
@use "../scss/Variables.scss" as vars;
@use "../scss/Toaster.scss";

.app-root {
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .footer {
        flex-shrink: 0;
    }

    @media screen and (max-width: vars.$breakpoint-medium) {
        .header__user-switch {
            position: fixed;
            bottom: 86px;
            right: 16px;
        }
    }
}

@media screen and (min-width: vars.$breakpoint-medium) {
    div.toast-specimen-user-switch {
        top: 80px;
    }
}

.subject-selection {
    width: 100%;

    fieldset {
        border: none;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .radio-wrapper {
            display: flex;
            align-items: center;
            border-radius: 6px;
            background: var(--block);
            border: 1px solid var(--block-border);

            &:hover,
            &:has(input[type="radio"]:checked) {
                background: var(--element-navigation-default);
                border: 1px solid var(--element-evidenceb-default);
            }

            input[type="radio"] {
                opacity: 0;
                width: 0;

                + label {
                    width: 100%;
                    padding: 32px 16px;
                    position: relative;
                    box-sizing: border-box;
                    cursor: pointer;
                    color: var(--text-main-default);

                    // margin-left:18px;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 22px;
                        border-radius: 50%;
                        border: 2px solid var(--text-main-default);
                        width: 20px;
                        height: 20px;
                        background: transparent;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: 27px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                        width: 14px;
                        height: 14px;
                    }
                }

                &:checked {
                    + label::before {
                        border-color: var(--element-evidenceb-default);
                    }

                    + label::after {
                        background: var(--element-evidenceb-default);
                    }
                }

                &:focus-visible {
                    + label {
                        outline: 2px solid;
                        border-radius: 6px;
                    }

                    + label::before {
                        box-shadow: 0 0px 8px var(--element-evidenceb-default);
                    }
                }

                &:disabled {
                    + label {
                        color: var(--text-main-disabled);
                    }

                    + label::before {
                        border-color: var(--text-main-disabled);
                    }

                    + label::after {
                        background: var(--text-main-disabled);
                    }
                }
            }
        }
    }
}

.offline-banner {
    color: var(--text-main-default);
    background-color: var(--element-navigation-default);
    padding: 0.75rem 1rem 0.75rem 0rem;
    position: sticky;
    z-index: 1;
}

.offline-banner button {
    color: var(--text-main-default);
}

.inline-center-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.wifi-off {
    visibility: hidden;
}

@media screen and (min-width: 768px) {
    // desktop
    .offline-banner {
        padding: 0.75rem 1rem 0.75rem 2.5rem;
    }
    .wifi-off {
        visibility: visible;
    }
}

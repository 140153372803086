@use "../../../scss/Variables" as vars;

#switch-btn {
    display: flex;
    align-items: center;
    padding: 16px;

    @media screen and (max-width: vars.$breakpoint-medium) {
        height: 44px;
    }
}

@use "../../scss/functions" as fn;
@use "../../scss/Variables.scss" as vars;

.account-settings {
    h2,
    p {
        color: var(--text-main-default);
    }

    h2 {
        font-size: fn.pxToRem(24);
        margin-bottom: 16px;
    }

    .account-settings__parameters {
        p {
            font-size: fn.pxToRem(14);
        }

        .account-settings__parameters__subject {
            > div:last-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-inline-start: auto;
                margin-bottom: 24px;
            }
        }

        .account-settings__parameters__avatar-title {
            display: flex;
            justify-content: space-between;

            .account-settings__parameters__current-avatar {
                display: flex;
                align-items: center;

                .account-settings__parameters__current-avatar__preview__default {
                    margin: 0px 10px;
                    height: 55px;
                    width: 55px;
                }

                .account-settings__parameters__current-avatar__preview__custom {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px 10px;
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;

                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .account-settings__parameters__avatar {
            h3 {
                font-size: fn.pxToRem(16);
                font-weight: 500;
                color: var(--text-main-default);
            }

            .character-and-background-choice__selection {
                .character-and-background-choice__selected-option {
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .character-and-background-choice__selected-option__default {
                    width: 119px;
                    height: 119px;
                }
            }

            .character-and-background-choice__options {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                padding: 24px 0px;

                .character-and-background-choice__option {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    background: var(--element-grey20);
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                &:focus-within
                    .character-and-background-choice__option.--checked {
                    outline: 2px solid var(--text-main-default);
                    outline-offset: 4px;
                    border-radius: 50%;
                }
            }
        }

        .account-settings__subject__error {
            margin-top: 8px;
        }

        .character-and-background-choice__footer {
            padding: 16px 0px;
            margin-bottom: 16px;
        }
    }
}

@media screen and (max-width: vars.$breakpoint-medium) {
    .account-settings {
        .account-settings__parameters {
            flex: 1;
            overflow-y: auto;

            .account-settings__parameters__subject {
                > div:last-child {
                    flex-direction: column;
                    justify-content: unset;
                    margin-bottom: 0px;
                    gap: 32px;
                    padding: 8px;
                }
            }
        }

        .account-settings__mobile-header {
            margin-top: 40px;
            margin-bottom: 24px;

            .account-settings__mobile-header__avatar-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 24px;

                > p {
                    margin-top: 24px;
                }

                .account-settings__mobile-header__current-avatar {
                    width: 48px;
                    height: 48px;
                }
            }

            .account-settings__mobile-header__button-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .account-settings__mobile-header__button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: fn.pxToRem(16);
                    color: var(--text-main-default);
                    background: var(--block);
                    padding: 16px;
                }
            }
        }

        h2.account-settings__parameters__mobile-subject-title {
            margin-bottom: 12px;
        }
    }

    .character-and-background-choice__selection {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 230px;
        padding: 24px 16px;

        .character-and-background-choice__selected-option {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 119px;
            height: 119px;
            border-radius: 50%;

            .selected-avatar {
                > img {
                    width: 97.364px;
                    height: 97.364px;
                }
            }
        }

        .character-and-background-choice__selected-option__avatar {
            width: 119px;
            height: 119px;
        }
    }

    .character-and-background-choice__options {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 24px 0px;

        .character-and-background-choice__option {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background: var(--element-grey20);

            > img {
                width: 100%;
                height: 100%;
            }
        }

        &:focus-within .character-and-background-choice__option.--checked {
            outline: 2px solid var(--text-main-default);
            outline-offset: 4px;
            border-radius: 50%;
        }
    }

    .character-and-background-choice__footer {
        padding: 16px 0px;
    }
}

@use "../../scss/functions";

.notifications {
    background: var(--block-header);

    header {
        display: flex;
        align-items: center;

        h2 {
            padding: 24px;
            font-size: functions.pxToRem(24);
        }

        button {
            margin-inline-start: auto;
            margin-inline-end: 24px;
            box-sizing: border-box;
            height: 60px;
            display: flex;
            align-items: center;

            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: 1px solid var(--text-main-default);
                border: 1px solid var(--text-main-inverted);
            }
        }
    }

    .notifications-tab-title {
        color: var(--text-main-default);
    }

    .notifications__tab--unread {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;

        .notifications-tab-unread__nb-chip {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .notification_no-notifications {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 24px;

        p {
            text-align: center;
        }
    }

    [role="tab"] {
        .notifications-tab-title {
            color: var(--text-main-default);
        }

        .notifications-tab-unread__nb-chip {
            color: var(--text-main-inverted);
            background: var(--text-main-default);
        }

        &[aria-selected="true"],
        &:hover {
            .notifications-tab-title {
                color: var(--user-color);
                font-weight: 500;
            }

            .notifications-tab-unread__nb-chip {
                color: var(--text-main-inverted);
                background: var(--user-color);
            }
        }
    }

    .notifications__list > * {
        border-bottom: 1px solid var(--text-main-disabled);
    }
}

.desktop-header {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
    gap: 20px;
    padding-inline-end: 40px;

    .header__navigation {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        gap: 1px;

        li {
            display: flex;
            position: relative;
            height: 100%;
            align-items: center;
            color: var(--text-main-default);

            a {
                font-size: 1em;
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                gap: 10px;
                padding: 0 20px;
                border-bottom: 3px transparent solid;
                transition: border-color 0.3s ease;
                cursor: pointer;

                span {
                    white-space: nowrap;

                    &.--icon-idle {
                        display: block;
                    }

                    &.--icon-active {
                        display: none;
                    }
                }

                &:hover,
                &.active {
                    border-bottom: 3px var(--element-evidenceb-default) solid;
                    color: var(--element-evidenceb-default);

                    span {
                        &.--icon-idle {
                            display: none;
                        }

                        &.--icon-active {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .desktop-nav__popover-trigger:hover {
        border: unset;
        border: 1px solid transparent;
        background-color: unset;
    }
}

.desktop-nav__popover-item {
    display: flex;
    gap: 12px;
}

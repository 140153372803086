@use "../../design-system-components/TriStateCheckbox/TriStateCheckbox.scss" as
    checkbox;
@use "@evidenceb/athena-common/sass-helpers/accordion" as accordion;

$checkbox-right-margin: 12px;
$btn-gap: 5px;
$btn-padding: 10px;
$clickableSize: 44px;

@mixin indent {
    @for $i from 1 through 6 {
        &.--indent-#{$i} {
            margin-left: calc($i * 16px);
        }
    }
}

@mixin list-item {
    background-color: var(--block-item1-default);
    border-radius: 6px;
    border: 2px solid var(--block-item1-border-default);
    align-items: center;

    &:hover {
        background: var(--block-item1-hover);
        border-color: var(--block-item1-border-hover);
    }

    &.--active,
    .tree-checkbox-list__title--active {
        background-color: var(--element-teacher-default);
        color: var(--text-main-inverted);
    }
}

.tree-checkbox {
    position: relative;
    isolation: isolate;

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1rem;
        font-weight: 500;
    }

    fieldset {
        border: none;
    }
}

.tree-checkbox-list__title {
    position: relative;
    margin-bottom: $btn-gap;
    isolation: isolate;
    display: flex;
    flex-direction: row;
    color: var(--text-main-default);
    background-color: var(--block-item1-default);
    border: 1px solid var(--block-item1-border-default);
    text-align: left;
    @include indent;
    @include list-item;
}

.tree-checkbox-list__title-content {
    padding: $btn-padding;
    padding-right: calc(
        $checkbox-right-margin + $btn-padding + checkbox.$size +
            ($clickableSize - checkbox.$size) / 2
    );
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--text-main-default);
    text-align: left;
}

.tree-checkbox-list__title-checkbox {
    position: absolute;
    top: 0;
    width: $clickableSize;
    height: $clickableSize;
    right: $checkbox-right-margin;
    z-index: 100;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
        background: var(--element-teacher-disabled);
    }
}

.tree-checkbox-list__leaf {
    display: flex;
    justify-content: space-between;
    @include indent;
    color: var(--text-main-default);
    position: relative;
    @include list-item;

    button {
        color: inherit;
        flex: 1;
        text-align: start;

        &:focus:not(:focus-visible) {
            outline: none;
        }

        &:focus-visible {
            outline: 12px solid var(--text-main-inverted);
        }
    }

    .leaf__checkbox {
        margin-right: calc($checkbox-right-margin - $btn-padding);
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $clickableSize;
        height: $clickableSize;

        &:only-child {
            cursor: pointer;
            flex: 1;
        }
    }

    &.--with-click-handler {
        padding-right: calc(
            $checkbox-right-margin + $btn-padding + checkbox.$size +
                ($clickableSize - checkbox.$size) / 2
        );

        .leaf__checkbox {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: $checkbox-right-margin;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                background: var(--element-teacher-disabled);
            }
        }
    }
}

li:not(:last-child) .tree-checkbox-list__leaf {
    margin-bottom: $btn-gap;
}

.leaf__label {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-inline-start: calc(accordion.$clickableArea / 2);
}

.tree-checkbox-list__leaf-button {
    padding: 10px;
}

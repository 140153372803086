.error-fallback {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-main-default);

    &__content {
        h1,
        p {
            margin-bottom: 32px;
        }
    }

    &__action {
        display: flex;
        justify-content: flex-end;

        a {
            padding: 16px 24px;
            border-radius: 6px;
            background: var(--element-evidenceb-default);
        }
    }
}

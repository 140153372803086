@use "functions";

:root {
    .toast-action-feedback {
        --toastify-color-light: var(--block-header);
        --toastify-text-color-light: var(--text-main-default);

        --toastify-toast-width: 458px;
        --toastify-toast-min-height: 44px;
        --toastify-font-family: var(--font-family);

        --toastify-color-progress-light: var(--text-main-disabled);
        color: var(--as-black, #192a3e);

        .Toastify__toast-body {
            font-size: functions.pxToRem(14);
            font-weight: 500;
            line-height: 120%;
        }
    }

    .toast-athena-event-saving-failure-notifier {
        --toastify-color-light: var(--block-header);
        --toastify-text-color-light: var(--text-main-default);
        --toastify-color-error: #e74c3c;
        --toastify-color-success: var(--block-item1-selected-subject2);

        --toastify-toast-width: 458px;
        --toastify-toast-min-height: 44px;
        --toastify-font-family: var(--font-family);

        --toastify-color-progress-light: var(--text-main-disabled);
        color: var(--as-black, #192a3e);

        .failure .Toastify__progress-bar {
            background-color: var(--toastify-color-error);
        }

        .success .Toastify__progress-bar {
            background-color: var(--toastify-color-success);
        }

        .failure .Toastify__toast-icon {
            color: var(--toastify-color-error);
        }

        .success .Toastify__toast-icon {
            color: var(--toastify-color-success);
        }

        .Toastify__toast-body {
            font-size: functions.pxToRem(14);
            font-weight: 500;
            line-height: 120%;
        }

        .Toastify__close-button {
            color: white;
            opacity: 1;
        }
    }

    .toast-progress-notifier {
        //--toastify-text-color-light: var(--text-main-default);

        --toastify-toast-width: 458px;
        --toastify-toast-min-height: 72px;
        --toastify-font-family: var(--font-family);

        .Toastify__toast {
            border-radius: 4px;
            background: white;

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }

        //--toastify-color-progress-light: var(--text-main-disabled);

        .Toastify__toast-body {
            color: var(--as-black, #192a3e);
            font-family: var(--font-family);
            font-size: functions.pxToRem(16);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-items: flex-start;
        }

        .Toastify__toast-icon {
            margin-inline-end: 10px;
            flex-shrink: 0;
            display: flex;
            width: 24px;
        }
    }

    // for planet.svg
    .progress-planet {
        --toastify-color-progress-light: linear-gradient(
            270deg,
            rgba(33, 29, 214, 0.49) 30.56%,
            rgba(255, 0, 92, 0.49) 69.01%,
            #ff005c 98.63%
        );
    }

    // now for planet4.svg  planet.svg  rocket3.svg  rocket.svg  star_angle.svg  star_fall.svg  star_rainbow.svg  stars_line.svg  stars_minimalistic.svg  ufo2.svg
    .progress-planet4 {
        --toastify-color-progress-light: linear-gradient(
            90deg,
            #ef629f 0%,
            #eecda3 100%
        );
    }

    .progress-rocket3 {
        --toastify-color-progress-light: linear-gradient(
            90deg,
            #8532ef 0%,
            #89fffd 100%
        );
    }

    .progress-rocket {
        --toastify-color-progress-light: linear-gradient(
            50deg,
            #688fdb -5.81%,
            #0065fc -5.8%,
            #6ec4e9 50.41%,
            #f1faff 105.47%
        );
    }

    .progress-star-angle {
        --toastify-color-progress-light: linear-gradient(
            270deg,
            rgba(236, 30, 141, 0.58) 30.56%,
            rgba(255, 0, 0, 0.49) 69.01%,
            #ffc700 98.63%
        );
    }

    .progress-star-fall {
        --toastify-color-progress-light: linear-gradient(
            90deg,
            #a770ef 0%,
            #cf8bf3 50%,
            #fdb99b 100%
        );
    }

    .progress-star-rainbow {
        --toastify-color-progress-light: linear-gradient(
            50deg,
            #fc9700 10.8%,
            #e96ece 50.41%,
            #16abff 91.22%
        );
    }

    .progress-stars-line {
        --toastify-color-progress-light: linear-gradient(
            228deg,
            #fff500 -2.54%,
            #ffa06a 72.14%,
            #ff8660 93.3%
        );
    }

    .progress-stars-minimalistic {
        --toastify-color-progress-light: linear-gradient(
            90deg,
            #12c2e9 0%,
            #c471ed 50%,
            #f64f59 100%
        );
    }
}

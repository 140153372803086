.motivational-message__non-submitted-feedback-form__cta-container {
    display: flex;
    justify-content: flex-end;
}

.motivational-message__submitted-feedback-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // Ugly hack to replace the motivational message text with the title text of
    // the submitted feedback form. Since it's not easy to replace "properly"
    // (we need to refactor motivMessages.tsx into a proper component), and we
    // need this to be done quickly, the following bit of css just puts a white
    // background on the submitted feedback form and moves it up to hide the
    // original motivational message text.
    background: white;
    position: relative;
    top: -20px;
    margin-bottom: -20px;
    h5 {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2em;
    }
    img {
        margin-bottom: 1em;
    }
    p {
        font-size: 15px;
    }
}

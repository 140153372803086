@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: local("Material Icons"), local("MaterialIcons-Regular"),
        url("../fonts/materialIcons/MaterialIcons-Regular.ttf")
            format("truetype");
}

@font-face {
    font-family: "Staatliches";
    font-style: normal;
    font-weight: 400;
    src: local("Staatliches"), local("Staatliches-Regular"),
        url("../fonts/staatliches/Staatliches-Regular.ttf") format("truetype");
}

@use "../../scss/mixins";

.ds-radio-group__input {
    @include mixins.visually-hidden;

    & + label {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &:focus-visible + label {
        @include mixins.focus;
    }
}

$circle-color: #afceeb;
$dot-color: #0078a2;

$dot_initial-rotation: -45deg;
$bottom-dot_initial-rotation: -155deg;
$middle-circle_initial-rotation: -45deg;
$outer-circle_initial-rotation: -45deg;

$size: 110px;

.evb-spinner__container {
    height: $size;
    width: $size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .evb-spinner__circle,
    .evb-spinner__dot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .evb-spinner__circle {
        border: 6px solid $circle-color;
        border-radius: 50%;
    }

    .evb-spinner__dot {
        z-index: 1;

        &::after {
            content: "";
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $dot-color;
            position: absolute;
        }
    }

    .evb-spinner--outer {
        width: 60px;
        height: 60px;
    }

    .evb-spinner--middle {
        width: 40px;
        height: 40px;
    }

    .evb-spinner--inner {
        width: 20px;
        height: 20px;
    }

    .evb-spinner--outer.evb-spinner__circle {
        border-bottom-color: transparent;
        transform: translate(-50%, -50%) rotate($outer-circle_initial-rotation);
    }

    .evb-spinner--middle.evb-spinner__circle {
        border-right-color: transparent;
        transform: translate(-50%, -50%) rotate($middle-circle_initial-rotation);
    }

    .evb-spinner__dot.evb-spinner--outer {
        transform: translate(-50%, -50%) rotate($dot_initial-rotation);

        &:after {
            top: -9px;
            left: -9px;
        }
    }

    .evb-spinner__dot.evb-spinner--middle {
        transform: translate(-50%, -50%) rotate(-45deg);

        &:after {
            top: -6px;
            left: -6px;
        }
    }

    .evb-spinner__dot.evb-spinner--inner {
        transform: translate(-50%, -50%) rotate(-45deg);

        &:after {
            top: -5px;
            left: -5px;
        }
    }

    .evb-spinner__dot.evb-spinner--bottom {
        transform: translate(-50%, -50%) rotate($bottom-dot_initial-rotation);

        &:after {
            top: -2px;
            left: -2px;
        }
    }
}

.evb-spinner__container.evb-spinner--animate {
    @keyframes steps-bottom-dot {
        0% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation);
        }
        5% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation);
        }
        12% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 110deg);
        }
        34% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 110deg);
        }
        35% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 130deg);
        }
        40% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 130deg);
        }
        45% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 200deg);
        }
        50% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 200deg);
        }
        55% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 290deg);
        }
        61% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 290deg);
        }
        65% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 360deg);
        }
        75% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 360deg);
        }
        100% {
            transform: translate(-50%, -50%)
                rotate($bottom-dot_initial-rotation + 360deg);
        }
    }
    @keyframes steps-outer-circle {
        0% {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        7% {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        34% {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        41% {
            transform: translate(-50%, -50%) rotate(45deg + 90deg);
        }
        45% {
            transform: translate(-50%, -50%) rotate(45deg + 90deg);
        }
        52% {
            transform: translate(-50%, -50%) rotate(45deg + 180deg);
        }
        55% {
            transform: translate(-50%, -50%) rotate(45deg + 180deg);
        }
        62% {
            transform: translate(-50%, -50%) rotate(45deg + 270deg);
        }
        75% {
            transform: translate(-50%, -50%) rotate(45deg + 270deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(45deg + 270deg);
        }
    }
    @keyframes steps-outer-dot {
        0% {
            transform: translate(-50%, -50%) rotate($dot_initial-rotation);
        }
        10% {
            transform: translate(-50%, -50%) rotate($dot_initial-rotation);
        }
        37% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
        75% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
        100% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
    }
    @keyframes steps-middle-dot {
        0% {
            transform: translate(-50%, -50%) rotate($dot_initial-rotation);
        }
        10% {
            transform: translate(-50%, -50%) rotate($dot_initial-rotation);
        }
        19% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 180deg);
        }
        22% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 180deg);
        }
        27% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 270deg);
        }
        31% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 270deg);
        }
        35% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
        75% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
        100% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
    }
    @keyframes steps-middle-circle {
        0% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation);
        }
        18% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation);
        }
        22% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 90deg);
        }
        27% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 90deg);
        }
        31% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 180deg);
        }
        65% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 180deg);
        }
        73% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 360deg);
        }
        75% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 360deg);
        }
        100% {
            transform: translate(-50%, -50%)
                rotate($middle-circle_initial-rotation + 360deg);
        }
    }
    @keyframes steps-inner-dot {
        0% {
            transform: translate(-50%, -50%) rotate($dot_initial-rotation);
        }
        65% {
            transform: translate(-50%, -50%) rotate($dot_initial-rotation);
        }
        73% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
        75% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
        100% {
            transform: translate(-50%, -50%)
                rotate($dot_initial-rotation + 360deg);
        }
    }

    .evb-spinner--outer.evb-spinner__circle {
        animation: steps-outer-circle 6000ms ease-in-out;
        animation-iteration-count: infinite;
    }
    .evb-spinner--outer.evb-spinner--bottom.evb-spinner__dot {
        animation: steps-bottom-dot 6000ms ease-in-out;
        animation-iteration-count: infinite;
    }
    .evb-spinner--outer.evb-spinner__dot {
        animation: steps-outer-dot 6000ms ease-in-out;
        animation-iteration-count: infinite;
    }
    .evb-spinner--middle.evb-spinner__dot {
        animation: steps-middle-dot 6000ms ease-in-out;
        animation-iteration-count: infinite;
    }
    .evb-spinner--middle.evb-spinner__circle {
        animation: steps-middle-circle 6000ms ease-in-out;
        animation-iteration-count: infinite;
    }
    .evb-spinner--inner.evb-spinner__dot {
        animation: steps-inner-dot 6000ms ease-in-out;
        animation-iteration-count: infinite;
    }

    @media (prefers-reduced-motion) {
        animation: none !important;
    }
}

.loader-busy-container {
    display: contents;
}

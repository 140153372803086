@use "../../../scss/functions";

.error-popup {
    border-radius: 10px;
    position: relative;
    .error-popup__title {
        margin: 0 0 24px 0;
        font-size: functions.pxToRem(24);
    }
    .error-popup__content {
        font-size: 1rem;
    }
    .alert-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 24px 0 0 0;
        button {
            width: max-content;
            margin: 0 0 0 16px;
        }
    }
}

@use "../../scss/functions" as fn;
@use "../../scss/Variables" as vars;

.header-top {
    width: 100%;
    background-color: var(--block-header);
    z-index: 3;
    position: sticky;
    top: 0;
    height: 70px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;

    .header__logo {
        padding: 0 40px;
        min-height: 70px;
        display: flex;
        align-items: center;

        a {
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    @media screen and (max-width: vars.$breakpoint-medium) {
        .header__logo {
            padding: 0 8px;
        }
    }

    .header__backlink {
        margin-left: 24px;
        border-radius: 10px;
        border-width: 1px;
    }

    .header__right-part {
        display: flex;
        align-items: center;
        margin-left: auto;
        color: var(--text-main-default);
        gap: 16px;
        padding-inline-end: 40px;

        &_user-avatar {
            color: var(--text-main-default);
            gap: 10px;

            &:not(button) {
                display: flex;
                align-items: center;
                padding-block: 8px;
            }
        }
    }

    @media screen and (max-width: vars.$breakpoint-medium) {
        .header__right-part {
            padding-inline-end: 8px;
        }
    }
}

.header__right-part_user-menu {
    * {
        outline: none;
    }

    li {
        > span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
        }

        &:first-child {
            cursor: default;
            padding: 24px 16px;

            &:hover {
                opacity: 1;
            }
        }

        &:not(:first-child) > * {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &[data-key="logout"] {
            border-top: 1px solid var(--text-main-disabled);

            button {
                color: var(--text-main-default);
            }
        }
    }
}

.ds-error {
    width: 100%;
    display: flex;
    padding: 16px;
    gap: 16px;
    background: var(--block-header);
    color: var(--text-main-default);
    align-items: center;
    box-sizing: border-box;

    p {
        text-align: left;
        margin: 0;
        padding: 0;

        a {
            padding-inline-start: 8px;
            color: var(--element-evidenceb-default);
        }
    }
}

.notification {
    display: flex;
    gap: 24px;
    padding: 32px 16px 32px 24px;

    &.--status-created {
        background-color: var(--block-item2-default);
    }

    &:hover {
        background-color: var(--element-navigation-default);
    }

    &__main {
        position: relative;

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }

            &:focus-visible {
                border: 2px solid;
            }
        }

        .notification__message-container {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .notification__message {
                display: flex;
                align-items: center;
                gap: 24px;
            }

            .notification__resource {
                display: flex;
                gap: 10px;
            }

            .notification__timestamp {
                color: var(--element-evidenceb-default);

                &.--teacher {
                    color: var(--element-teacher-default);
                }
            }
        }
    }

    &__read-status {
        display: flex;
        align-items: center;
        margin-left: auto;

        button {
            &:focus {
                outline: none;
            }

            &.--read {
                .notification__read-status--read-hover,
                .notification__read-status--unread,
                .notification__read-status--unread-hover {
                    display: none;
                }

                &:hover {
                    .notification__read-status--read {
                        display: none;
                    }

                    .notification__read-status--read-hover {
                        display: block;
                    }
                }
            }

            &.--unread {
                .notification__read-status--read,
                .notification__read-status--read-hover,
                .notification__read-status--unread-hover {
                    display: none;
                }

                &:hover {
                    .notification__read-status--unread {
                        display: none;
                    }

                    .notification__read-status--unread-hover {
                        display: block;
                    }
                }
            }

            &:focus-visible {
                outline: 1px solid var(--text-main-default);
                border: 1px solid var(--text-main-inverted);
            }
        }
    }
}

$loaderSize: 24px;

.evb-spinner--small {
    display: inline-block;
    position: relative;
    width: var(--evb-spinner--small-size);
    height: var(--evb-spinner--small-size);

    span {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: calc((80 * var(--evb-spinner--small-size)) / 100);
        height: calc((80 * var(--evb-spinner--small-size)) / 100);
        margin: var(--evb-spinner--small-border-width);
        border: var(--evb-spinner--small-border-width) solid
            var(--evb-spinner--small-color);
        border-radius: 50%;
        animation: evb-spinner-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--evb-spinner--small-color) transparent transparent
            transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    @keyframes evb-spinner-small {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

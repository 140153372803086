.popover-menu__menu {
    background: var(--block);
    border-radius: 8px;
    color: var(--text-main-default);
    border: 1px solid var(--element-grey20);
    width: 257px;
    gap: 11px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;

    .popover-menu__item {
        display: flex;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        color: var(--text-main-default);
        border-radius: 6px;

        > * {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--text-main-default);
        }

        &:hover {
            background: var(--block-item1-default);
        }
    }
}

.popover-menu__trigger {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--text-main-default);
    cursor: pointer;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid transparent;

    &:hover,
    &[aria-expanded="true"] {
        border-color: var(--element-grey20);
        background: var(--block-item2-default);
    }
}

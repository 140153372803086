$size: 16px;

.ds-tri-state-checkbox .checkbox {
    box-sizing: border-box;
    width: $size;
    height: $size;
    border: 1px solid;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    $accent-color: var(--accent-color, var(--color-secondary));
    $font-color: var(--text-main-inverted);

    &--true,
    &--mixed {
        background-color: $accent-color;
        border-color: $accent-color;
        color: $font-color;

        .mixed-icon {
            font-size: 1.5rem;
            position: relative;
            top: -1px; //optical alignment
        }

        &.checkbox--variant-inverted {
            background-color: $font-color;
            border-color: $font-color;
            color: $accent-color;
        }
    }

    &--false {
        border-color: var(--text-main-default);

        &.checkbox--variant-inverted {
            border-color: $font-color;
        }
    }
}
